import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { element } from 'prop-types';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// client routing
const ClientListPage = Loadable(lazy(() => import('views/pages/clients/ClientList')));
const ClientAccountPage = Loadable(lazy(() => import('views/pages/clients/ClientAccount')));
const AddNewClientPage = Loadable(lazy(() => import('views/pages/clients/AddNewClient')));
const NewRegistrationPage = Loadable(lazy(() => import('views/pages/clients/registration/NewRegistration')));

// charges routing
const ChargesPage = Loadable(lazy(() => import('views/pages/charges/index')));

// schedule routing
const SchedulePage = Loadable(lazy(() => import('views/pages/schedule/index')));
const SummerSchedulePage = Loadable(lazy(() => import('views/pages/summerSchedule/index')));

// team routing
const TeamPage = Loadable(lazy(() => import('views/pages/splashTeam/TeamList')));
const NewTeamMemberPage = Loadable(lazy(() => import('views/pages/splashTeam/NewTeamMember')));

// pool partner routing
const PartnerPage = Loadable(lazy(() => import('views/pages/splashPool/PartnerList')));
const NewPoolPartnerPage = Loadable(lazy(() => import('views/pages/splashPool/NewPoolPartner')));

// year round sessions routing
const NewSessionPage = Loadable(lazy(() => import('views/pages/yearRoundSessions/NewSession')));
const NewSessionalSchedulePage = Loadable(lazy(() => import('views/pages/yearRoundSessions/NewSessionalSchedule')));
const NewLessonTypePage = Loadable(lazy(() => import('views/pages/yearRoundSessions/NewLessonType')));

// reports routing
const ReportsPage = Loadable(lazy(() => import('views/pages/reports/index')));
const RemainingLessonsPage = Loadable(lazy(() => import('views/pages/reports/RemainingLessons')));
const PayrollPage = Loadable(lazy(() => import('views/pages/reports/TeamPayroll')));
const PoolPartnerHoursPage = Loadable(lazy(() => import('views/pages/reports/PoolPartnerHours')));

// admin tasks routing
const AdminTasksPage = Loadable(lazy(() => import('views/pages/adminTasks/AdminTasks')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/clients',
            element: <ClientListPage />
        },
        {
            path: '/clients/search/:search',
            element: <ClientListPage />
        },
        {
            path: '/clients/add',
            element: <AddNewClientPage />
        },
        {
            path: '/clients/account/:id',
            element: <ClientAccountPage />
        },
        {
            path: '/clients/register',
            element: <NewRegistrationPage />
        },
        {
            path: '/charges',
            element: <ChargesPage />
        },
        {
            path: '/year-round-sessions',
            element: <SchedulePage />
        },
        {
            path: '/summer-schedule',
            element: <SummerSchedulePage />
        },
        {
            path: '/splash-team',
            element: <TeamPage />
        },
        {
            path: '/new-team-member',
            element: <NewTeamMemberPage />
        },
        {
            path: '/new-pool',
            element: <NewPoolPartnerPage />
        },
        {
            path: '/splash-pools',
            element: <PartnerPage />
        },
        {
            path: '/new-session',
            element: <NewSessionPage />
        },
        {
            path: '/new-schedule',
            element: <NewSessionalSchedulePage />
        },
        {
            path: '/new-lesson-type',
            element: <NewLessonTypePage />
        },
        {
            path: '/tasks',
            element: <AdminTasksPage />
        },
        {
            path: '/reports',
            element: <ReportsPage />
        },
        {
            path: '/reports/remaining-lessons',
            element: <RemainingLessonsPage />
        },
        {
            path: '/reports/payroll',
            element: <PayrollPage />
        },
        {
            path: '/reports/partner-hours',
            element: <PoolPartnerHoursPage />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
