import { useSelector } from 'react-redux';


import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// routing
import Routes from 'routes';

// defaultTheme


// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AuthProvider } from 'hooks/AuthContext';
import { FirebaseProvider } from 'hooks/FirebaseContext';
import { SupabaseProvider } from 'hooks/SupabaseContext';

import { CssBaseline } from '@mui/material';
import theme from 'themes';

// ==============================|| APP ||============================== //

const App = () => {
    // Determine if the user is logged in and adjust the routes accordingly
    const themes = theme();
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <AuthProvider>
                    <FirebaseProvider>
                        <SupabaseProvider>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </SupabaseProvider>
                    </FirebaseProvider>
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
