import dayjs from "dayjs";
import { createContext, useContext } from "react";
import { supabase } from "supabaseClient";
import { useAuth } from "./AuthContext";


const SupabaseContext = createContext();

export function useSupabase() {
    return useContext(SupabaseContext);
}

export function SupabaseProvider({ children }) {

    const { currentUser } = useAuth();

    const getAllBookings = async () => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('status', 'booked')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
        if (error) {
            console.log('error', error)
        }

        return data
    }

    const getAllBookingsCount = async () => {
        const { count, error } = await supabase
            .from('lesson_timeslots')
            .select('*', { count: 'exact' })
            .eq('status', 'confirmed')
        if (error) {
            console.log('error', error)
        }
        return count
    }

    const getBookingsForUser = async (uid) => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('user_id', uid)
            .eq('status', 'booked')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
        if (error) {
            console.log('error: ', error)
        }

        return data
    }

    const getPendingBookingsCount = async () => {
        const { count, error } = await supabase
            .from('lesson_timeslots')
            .select('*', { count: 'exact' })
            .eq('status', 'reserved')
        if (error) {
            console.log('error', error)
        }
        return count
    }

    const getBookingsByDay = async (date) => {
        const startOfDay = date.startOf('day');
        const endOfDay = date.endOf('day');
        console.log('startOfDay', startOfDay.toDate());
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('status', 'booked')
            .lte('booking_time', endOfDay)
            .gte('booking_time', startOfDay)
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
            .order('booking_time', { ascending: true })
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const getUnbookedLessonCount = async () => {
        const availableLessons = [];
        const {count, error} = await supabase
            .from('lesson_timeslots')
            .select('*', { count: 'exact' })
            .eq('status', 'available')
        if (error) {
            console.log('error', error)
        }
        return count
    }

    const getAvailableServices = async () => {
        const {data, error } = await supabase
            .from('services')
            .select('*')
        if(error) {
            return error;
        }
        return data
    }

    const getAvailablePools = async (region) => {
        const {data, error} = await supabase
            .from('pools')
            .select('*')
            .eq('region', region)
        if(error) {
            return error
        }
        return data
    }

    const getInstructors = async () => {
        const {data, error} = await supabase
            .from('instructors')
            .select('*')
        if(error) {
            return error
        }
        return data
    }

    const addBookingForUser = async (booking) => {
        const { data, error } = await supabase
            .from('bookings')
            .insert(booking)
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const getLessonsForUser = async (uid) => {
        const { data, error } = await supabase
            .from('lesson_timeslots')
            .select('*')
            .eq('client_id', uid)
            .eq('status', 'confirmed')
            .select(
            `
            *,
            pool:pool_id(*),
            instructor:instructor_id(*),
            session:session_id(*)
            `
            )
            .order('start_date', {referencedTable: 'session', ascending: true})
            .order('day_of_week', {ascending: true})
            .order('start_time', {ascending: true})
        if (error) {
            console.log('error: ', error)
        }

        return data
    }

    const getAllLessonTimeslotsForCurrentSession = async () => {
        const { data, error } = await supabase
            .from('lesson_timeslots')
            .select('*')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*),
                session:session_id(*)
                `
            )
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const getUpcomingSessions = async () => {
        const { data, error } = await supabase
            .from('sessions')
            .select('*')
            .gte('start_date', dayjs().format('YYYY-MM-DD'))
            .order('start_date', {ascending: true})
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const getRegisteringSessions = async () => {
        const { data, error } = await supabase
            .from('sessions')
            .select('*')
            .lte('registration_open', new Date().toISOString())
            .gte('registration_closed', new Date().toISOString())
            .order('start_date', {ascending: true})
        if (error) {
            console.log('error', error)
        }
        console.log(data);
        return data
    }

    const addLessonTimeslots = async (schedule) => {
        const daysOfWeek = Object.keys(schedule.days);
        const timeslots = [];

        daysOfWeek.forEach(day => {
            const daySchedule = schedule.days[day];
            let currentTime = daySchedule.startTime;

            while (currentTime.isBefore(daySchedule.endTime)) {
            const endTime = currentTime.add(40, 'minute');
            if (endTime.isAfter(daySchedule.endTime)) break;

            timeslots.push({
                session_id: schedule.sessionId.id,
                start_time: currentTime.format('HH:mm:ss'),
                end_time: endTime.format('HH:mm:ss'),
                day_of_week: day,
                pool_id: daySchedule.poolId,
                cost_per_lesson: daySchedule.costPerLesson,
                status: 'available',
                off_dates: daySchedule.offDates.map(date => dayjs(date).format('YYYY-MM-DD'))
            });

            currentTime = endTime;
            }
        });

        const { data, error } = await supabase
            .from('lesson_timeslots')
            .insert(timeslots);

        if (error) {
            console.log('error', error);
        }

        return data;
    }

    const getAvailableLessonsForSession = async (sessionId) => {
        const { data, error } = await supabase
            .from('lesson_timeslots')
            .select('*')
            .eq('session_id', sessionId)
            .eq('status', 'available')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*),
                session:session_id(*)
                `
            )
            .order('day_of_week', {ascending: true})
            .order('start_time', {ascending: true})
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const registerClientInLessonTimeslot = async (lessonId, clientId) => {
        const { data, error } = await supabase
            .from('lesson_timeslots')
            .update({client_id: clientId, status: 'confirmed'})
            .eq('id', lessonId)
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const addChargeToClientBalance = async (clientId, amount, eventType, description, lessonId) => {
        // Create a new entry in the 'user_balance_events' table
        const { data, error } = await supabase
            .from('user_balance_events')
            .insert({
                user_id: clientId,
                amount,
                event_type: eventType,
                created_by: currentUser.uid,
                description,
                lesson_timeslot_id: lessonId
            })
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const getAllPrograms = async () => {
        const { data, error } = await supabase
            .from('programs')
            .select('*')
        if (error) {
            console.log('error', error)
        }
        return data
    }

    const value = {
        getAllBookings,
        getAllBookingsCount,
        getPendingBookingsCount,
        getBookingsByDay,
        getAvailableServices,
        getAvailablePools,
        getInstructors,
        addBookingForUser,
        getBookingsForUser,
        getUnbookedLessonCount,
        getLessonsForUser,
        getAllLessonTimeslotsForCurrentSession,
        getUpcomingSessions,
        getRegisteringSessions,
        addLessonTimeslots,
        getAvailableLessonsForSession,
        registerClientInLessonTimeslot,
        addChargeToClientBalance,
        getAllPrograms,
    }

    return (
        <SupabaseContext.Provider value={value}>
            {children}
        </SupabaseContext.Provider>
    )
};